const tailwind = require('../tailwind')

module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteTitle: 'Vivian - celebrates 3!', // Navigation and Site Title
  siteTitleAlt: 'Vivian at 3', // Alternative Site title for SEO
  siteTitleShort: 'Viv @ 3', // short_name for manifest
  siteHeadline: 'Party like a 3 year old.', // Headline for schema.org JSONLD
  siteUrl: 'http://3.vivlamar.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/logo.png', // Used for SEO and manifest
  siteDescription: '3rs Birthday Party Invite for Vivian',
  author: 'LekoArts + Jason', // Author for schema.org JSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional

  ogLanguage: 'en_US',
  googleAnalyticsID: 'UA-47519312-X',

  // Manifest and Progress color
  themeColor: tailwind.colors.orange,
  backgroundColor: tailwind.colors.blue,
}
