import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Parallax } from 'react-spring/renderprops-addons.cjs'

// Components
import Layout from '../components/Layout'
import ProjectCard from '../components/ProjectCard'

// Elements
import Inner from '../elements/Inner'
import { Title, BigTitle, Subtitle } from '../elements/Titles'

// Views
import Hero from '../views/Hero'
import Projects from '../views/Projects'
import About from '../views/About'
import Contact from '../views/Contact'

import avatar from '../images/viv.jpg'

const ProjectsWrapper = styled.div`
  ${tw`flex flex-wrap justify-between mt-8`};
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`

const AboutHero = styled.div`
  ${tw`flex flex-col lg:flex-row items-center mt-8`};
`

const Avatar = styled.img`
  ${tw`rounded-full w-32 xl:w-48 shadow-lg h-auto`};
`

const AboutSub = styled.span`
  ${tw`text-white pt-12 lg:pt-0 lg:pl-12 text-2xl lg:text-3xl xl:text-4xl`};
`

const AboutDesc = styled.p`
  ${tw`text-grey-light text-lg md:text-xl lg:text-2xl font-sans pt-6 md:pt-12 text-justify`};
`

const ContactText = styled.p`
  ${tw`text-grey-light font-sans text-xl md:text-2xl lg:text-3xl`};
`

const Footer = styled.footer`
  ${tw`text-center text-grey absolute pin-b p-6 font-sans text-md lg:text-lg`};
`

const Index = () => (
  <>
    <Layout />
    <Parallax pages={5}>
      <Hero offset={0}>
        <BigTitle>
          Vivian turns 3!
        </BigTitle>
        <Subtitle>Bring your joyful selves and help us celebrate with cake, bubbles, pinatas and more!</Subtitle>
        <Subtitle>Saturday, 4th of May, Princes Park.</Subtitle>
      </Hero>
      <Projects offset={1}>
        <Title>The deets</Title>
        <ProjectsWrapper>
          <ProjectCard
            title="Playground at Princes Park"
            link="https://goo.gl/maps/KCW23Bqep6NLrupT8"
            bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
          >
            We hope this is close enough 
          </ProjectCard>
          <ProjectCard
            title="May the 4th, Saturday, 11 - 1pm"
            link="https://calendar.google.com/event?action=TEMPLATE&tmeid=M2ViY3FhYWtjOXJlaGpjZTBiM2lvYjRkYm0gZHZzbG02ZWs2MGkxc3RsNjlvaXNyZHFmaTBAZw&tmsrc=dvslm6ek60i1stl69oisrdqfi0%40group.calendar.google.com"
            bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
          >
            A late Saturday morning. You're welcome to bring the siblings along.
            Click this to add it to your calendars.
          </ProjectCard>
          <ProjectCard
            title="RSVP"
            link="mailto:dsouza.jason@gmail.com?subject=RSVP:Viv3"
            bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)"
          >
            Let us know if you can make it and how many of you will be there. 
          </ProjectCard>
          <ProjectCard
            title="Call us!"
            bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
          >
            Jason — <a href="tel:+61401173230">0401173230</a><br/>
            Daiv — <a href="tel:+61409419390">0409419390</a>
          </ProjectCard>
        </ProjectsWrapper>
      </Projects>
      <About offset={3}>
        <Title>More deets</Title>
        <AboutHero>
          <Avatar src={avatar} alt="Viv" />
          <AboutSub>
            We cannot promise there will be no poo poo jokes. We hope you take some good ones home with you.
          </AboutSub>
        </AboutHero>
        <AboutDesc>
          Let us know of any food allergies we should be mindful of. We will be outdoors and weather is a thing. RSVP and we will get in touch with any alternate plans if it looks like rain. 
        </AboutDesc>
      </About>
      <Contact offset={4}>
        <Inner>
          <Title>Get in touch</Title>
          <ContactText>
          Jason — <a href="tel:+61401173230">0401173230</a><br/>
          Daiv — <a href="tel:+61409419390">0409419390</a><br/>
          <a href="mailto:dsouza.jason@gmail.com?subject=RSVP:Viv3">RSVP by mail</a>
          </ContactText>
        </Inner>
        <Footer>
          
        </Footer>
      </Contact>
    </Parallax>
  </>
)

export default Index
